<ng-container *ngIf="redactedService.shouldRedact; else normalUserInfo">
  <div *ngIf="lead$ | async as lead" class="user-info text-center py-4 d-none d-xl-block">
    <h2 class="contact-name">{{ lead.lead_phone | phone }}</h2>
  </div>
</ng-container>

<ng-template #normalUserInfo>
  <div *ngIf="lead$ | async as lead" class="user-info text-center py-4 d-none d-xl-block">
    <h2 id="contact-name">{{ lead.first_name }} {{ lead.last_name }}</h2>
    <h4 id="contact-phone">{{ lead.lead_phone | phone }}</h4>
  </div>

  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-pills justify-content-center">
    <li ngbNavItem="details">
      <a ngbNavLink>{{ 'convHeader.details' | translate }}</a>
      <ng-template ngbNavContent>
        <app-conv-sidebar-lead-info
          [data]="leadTabData$ | async"
          [conversation]="conversation"
        ></app-conv-sidebar-lead-info>
      </ng-template>
    </li>

    <li ngbNavItem="questions">
      <a ngbNavLink>{{ 'convHeader.questions' | translate }}</a>
      <ng-template ngbNavContent>
        <app-conv-sidebar-questions (templateSelected)="onTemplateSelection($event)"> </app-conv-sidebar-questions>
      </ng-template>
    </li>

    <li ngbNavItem="templates">
      <a ngbNavLink>{{ 'convHeader.templates' | translate }}</a>
      <ng-template ngbNavContent>
        <app-conv-sidebar-templates (selected)="onTemplateSelection($event)"> </app-conv-sidebar-templates>
      </ng-template>
    </li>
  </ul>

  <div
    *ngIf="eventMenuSelection$ | async"
    [ngbNavOutlet]="nav"
    class="side-bar"
    [class.scrollable]="activeTab !== 'templates'"
  ></div>
</ng-template>
